<template>
  <page pageTitle="Projects" customClass="is-projects mt-6" :hasRightBar="false">
    <div v-if="loading && filtered_projects">
      <loading-state />
    </div>

    <div v-else>
      <div class="is-relative mb-5 mt-6 ">
        <columns class="is-multiline">
          <column class="is-4">

            <div class="field box is-shadowless p-2">
              <div class="control has-icons-right input-search">
                <input
                  class="input"
                  type="text"
                  placeholder="Type Project name"
                  v-model="filterVal"
                >
                <span class="icon is-small is-right ">
                   <unicon name="search" fill="" />
                 </span>
              </div>
            </div>
          </column>

          <column class="is-4">
            <new-project class="is-medium mt-1 is-rounded">
              <unicon name="plus" fill="#fff" />
            </new-project>
          </column>
        </columns>
      </div>

      <div class="is-relative project-lists pt-6">
        <!-- {{ filtered_projects }} -->
        <transition-group name="project-list" tag="div" class="columns is-multiline">
          <project-preview-item v-for="item in filtered_projects" :key="item" :project="item" class="column is-one-fifth" />
        </transition-group>
      </div>

    </div>
  </page>
</template>

<script>

import {reactive, toRefs, computed, watch, defineAsyncComponent } from 'vue'
import { getAllProjects } from '@/use/useProject'


export default {
  components: {
    Page: defineAsyncComponent(() => import('@/components/layout/Page')),
    LoadingState: defineAsyncComponent(() => import('@/components/general/Loading')),
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
    ProjectPreviewItem: defineAsyncComponent(() => import('@/components/projects/listing/preview')),
    NewProject: defineAsyncComponent(() => import('@/components/projects/create/Button')),

  },
  setup() {
    const state = reactive({
      filterVal: ''
    });

    const { loading, selected, filtered_projects, filterProjectsHandle } = getAllProjects()

    const filterHandle = () => {
      filterProjectsHandle(state.filterVal)
    }

    watch(
      () => state.filterVal,
      (val, prevVal) => {
        filterHandle()
      }
    )


    return {
      loading,
      selected,
      filtered_projects,
      filterHandle,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss">
  revo-grid {
    height: 100%;

    .main-viewport {
      height: auto;
    }

    .header-wrapper {
      background: hsl(0, 0%, 96%);
      font-weight: bolder;
    }

  }
  .input-search .input{
    border-color: transparent;
    box-shadow: none;
    outline: 0 none;

    &:focus,
    &:active,
    &:hover {
      border-color: transparent;
      box-shadow: none;
      outline: 0 none;
    }
  }

  .project-lists {
    min-height: 400px
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 1s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
</style>
